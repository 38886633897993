import * as Sentry from "@sentry/nuxt";

export default defineNuxtRouteMiddleware((to) => {
  if (!to.path.includes("/calculator")) {
    return;
  }
  const { $pinia } = useNuxtApp();

  const { registrationParams } = useOnboardingStore($pinia);

  if (!registrationParams.sex) {
    Sentry.captureMessage("Sex parameter missing in calculator funnel.", {});
    return navigateTo("/onboarding");
  }
});
